(function() {
  var $facetNavButtons = $('.facet__list.applied-filters li button');
  $facetNavButtons.each(function(){
    $(this).on("click", function() {
      var navUrl = $(this).attr('data-facet-nav-url');
      if(navUrl) {
        window.location.href = navUrl;
      }
    });
  });
})();